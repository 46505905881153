import React from "react"
import {withPrefix} from "gatsby"
// import { Helmet } from "react-helmet"
// import { Link } from "gatsby"

import "../../../../styles/core.css"
import "../../../../styles/styles.css"
import Layout from "../../../../components/Layout"
import MenuOne from "../../../../components/MenuOne"
import MenuTwo from "../../../../components/MenuTwo"
import MenuThree from "../../../../components/MenuThree"
import Content from "../../../../components/Content"
import Gallery from "../../../../components/Gallery"
import MenuFour from "../../../../components/MenuFour"

const galImgs = ['/page-images/braniel-3582_1.jpg']

export default function OurWork() {
  return (
    <Layout breadcrumbs={["Extensions"]}>
      <Gallery items={
      galImgs.map(image => ({
          original: withPrefix(image),
          thumbnail: withPrefix(image),
        })
      )}
    />

<Content />

      <div class="region region-content-bottom">
        <MenuOne active='our-work' />
        <MenuTwo
          active=""
          items={[
            '/our-work/residential',
            '/our-work/commercial',
            '/our-work/industrial',
            '/our-work/leisure',
            '/our-work/community',
            '/our-work/retail',
          ]} />
        <MenuThree
          items={[
            '/our-work/residential/houses',
            '/our-work/residential/extensions',
            '/our-work/residential/apartments',
            '/our-work/residential/developments',
          ]} />
        <MenuFour
          items={[
            '/our-work/residential/extensions/extension-one',
            '/our-work/residential/extensions/extension-two',
            '/our-work/residential/extensions/extension-three',
            '/our-work/residential/extensions/extension-four',
            '/our-work/residential/extensions/extension-five',
            '/our-work/residential/extensions/extension-six',
            '/our-work/residential/extensions/extension-seven',
          ]}
        />
      </div>

  </Layout>

  )
}
